export class AdvertiserRegistrationController {

	/* @ngInject */
	constructor($scope, config, AdvertiserRegistrar, CommonFunctions) {
		this.scope = $scope;
        this.scope.prepareSelectedMatch = CommonFunctions.prepareSelectedMatch;
        $scope.tac = JSON.parse(config.tac.value);
        // $scope.loggedInWithGoogle = false;

		$scope.user = {};

		$scope.niches = config.niches;

        $scope.im_types = [
            {name: 'Google'},
            {name: 'Skype'},
            {name: 'Telegram'},
            {name: 'WhatsApp'}
        ];

        $scope.heard_from_sources = [
            {name: 'Search Engine'},
            {name: 'Social Media'},
            {name: 'Recommendation'},
            {name: 'Trade Show'},
            {name: 'Email'},
            {name: 'Trade Publication'},
            {name: 'Other'},
        ];

        $scope.describe_types = [
            {name: 'Direct'},
            {name: 'Indirect'}
        ];

		$scope.marketing_spends = [
			{value: "$0-$9,999", name: "$0-$9,999"},
			{value: "$10,000-$49,999", name: "$10,000-$49,999"},
			{value: "$50,000-$149,999", name: "$50,000-$149,999"},
			{value: "$150,000-$499,999", name: "$150,000-$499,999"},
            {value: "$500,000-$999,999", name: "$500,000-$999,999"},
			{value: "$1,000,000", name: "$1,000,000+"}
		];

        $scope.tracking_platform_choices = [
            {name: 'Adjust'},
            {name: 'Appsflyer'},
            {name: 'Branch'},
            {name: 'Cake'},
            {name: 'CJ - Commission Junction'},
            {name: 'Everflow'},
            {name: 'GTM - Google Tag Manager'},
            {name: 'HasOffers / Tune'},
            {name: 'Impact Radius'},
            {name: 'Kochava'},
            {name: 'Konnektive CRM'},
            {name: 'Limelight CRM'},
            {name: 'Proprietary'},
            {name: 'Singular'},
            {name: 'ThriveTracker'},
            {name: 'Voluum'},
            {name: 'None - I do not have a tracking platform'},
            {name: 'Other', focus: false},

        ];

        $scope.uploadedFiles = {
            files: []
        };

        $scope.errors = {
            messages: null
        };

        $scope.lock_submit_till_response = false;

        $scope.user.referee_code = config.referee_code;

        $scope.hasError = function (ngModel) {
			if (typeof ngModel == 'undefined' || ngModel.$touched === false) {
				return false;
			}

			return ngModel.$invalid;
		};

        $scope.showErrors = function(response) {
            var errors = angular.copy(response);
            if(!isUndefinedOrNull(errors.error) && !isUndefinedOrNull(errors.error.message)) {
                errors = [[errors.error.message]];
            } else if(!isUndefinedOrNull(errors.message)) {
                errors = [[errors.message]];
            }

            $scope.errors.messages = errors;
        };

        $scope.clearErrors = function() {
            $scope.errors.messages = null
        };

        $scope.remove = function (index, files) {
            files.splice(index, 1);
        };

        $scope.prevent_multiple_submits = function (lock){
            $scope.lock_submit_till_response = lock;
        };

        $scope.register = function (files) {
            $scope.clearErrors();
            $scope.prevent_multiple_submits(true);
            var data = angular.copy($scope.user);
            let pardot_data = $scope.getPardotFields();
            data = angular.extend({},data, pardot_data);

            grecaptcha.ready(function() {
                grecaptcha.execute($scope.get_recaptcha_site_key(), {action: 'registerForm'}).then(function(token) {
                    if(token) {
                        data.recaptcha_v3 = token;

                        AdvertiserRegistrar.register(data, files)
                            .success(function (response, status, headers, config) {
                                $scope.prevent_multiple_submits(false);
                                if(!isUndefinedOrNull(response.data.redirect_to)) {
                                    window.location.href = response.data.redirect_to;
                                }
                            })
                            .error(function (response) {
                                $scope.prevent_multiple_submits(false);
                                $scope.showErrors(response);
                            });
                    }
                });
            });
        };

        $scope.getPardotFields = function () {
            let pardot = [];
            pardot ['First_Source'] = $( "input[name='First_Source']" ).val();
            pardot ['First_Medium'] = $( "input[name='First_Medium']" ).val();
            pardot ['First_Term'] = $( "input[name='First_Term']" ).val();
            pardot ['First_Content'] = $( "input[name='First_Content']" ).val();
            pardot ['First_Campaign'] = $( "input[name='First_Campaign']" ).val();
            pardot ['Last_Source'] = $( "input[name='Last_Source']" ).val();
            pardot ['Last_Medium'] = $( "input[name='Last_Medium']" ).val();
            pardot ['Last_Term'] = $( "input[name='Last_Term']" ).val();
            pardot ['Last_Content'] = $( "input[name='Last_Content']" ).val();
            pardot ['Last_Campaign'] = $( "input[name='Last_Campaign']" ).val();

            return {pardot: pardot};
        };

        $scope.changeFocus = function (item, $event) {
            $event.stopPropagation();
            $event.preventDefault();
            item.focus = true;
        };

        $scope.onKeypress = function ($event, item, new_element, selected_items) {
            if ($event.which === 13) {
                item.focus = false;
                item.is_selected = false;
                $event.stopPropagation();
                $event.preventDefault();
                $scope.addOther(new_element, selected_items);
            }
        };

        $scope.addOther = function (new_element, selected_items) {
            new_element = new_element ? new_element.trim() : '';
            if (!isUndefinedOrNull(new_element) && new_element.length != 0) {

                let element = {
                    name: new_element,
                    is_selected: true
                };

                if (new_element.toUpperCase() == 'OTHER') {
                    alert('Invalid input!');
                } else if (!$scope.tracking_platform_choices.find(x => x.name == new_element)) {
                    $scope.tracking_platform_choices.splice($scope.tracking_platform_choices.length - 1, 0, element);

                    if (isUndefinedOrNull(selected_items)) {
                        $scope.user.tracking_platform = element;
                        $scope.user.tracking_platform = asArray($scope.user.tracking_platform );
                    } else if(!isUndefinedOrNull(selected_items) && selected_items.length >= 1 && selected_items[0].name == 'None - I do not have a tracking platform') {
                        selected_items[0] = element;
                    } else {
                        selected_items.push(element);
                    }

                } else {
                    alert('Duplicate entry!');
                }

            } else {
                alert('Invalid input!');
            }
        };

        $scope.submitOther = function ($event, item, new_element, selected_items) {
            item.focus = false;
            item.is_selected = false;
            $event.stopPropagation();
            $event.preventDefault();
            $scope.addOther(new_element, selected_items);
        };

        $scope.discardOther = function ($event, item) {
            $event.stopPropagation();
            $event.preventDefault();
            item.focus = false;
        };

        $scope.changedTrackingPlatform = function (selected_items) {
            if (isUndefinedOrNull(selected_items) || selected_items.length < 1) {
                $scope.user.tracking_platform = undefined;
            } else {
                $scope.user.tracking_platform = asArray(selected_items);
            }
        };

        $scope.get_recaptcha_token = function () {
             return $( "input[name='recaptcha_v3']" ).val();
        };

        $scope.get_recaptcha_site_key = function () {
            return $( "input[name='site_key']" ).val();
        };
    }

	fill (data) {
		this.scope.$applyAsync(() => {
			if (data.toa == 'on') {
				data.toa = true;
			}
			if (data.niche_id) {
				this.scope.user.niche_id = data.niche_id;
			}
			if (data.marketing_spend) {
				this.scope.user.marketing_spend = data.marketing_spend;
			}

			angular.extend(this.scope, data);
            this.scope.user.website = data.websites;
		});
	}

	addFocus() {
	    var element = $('tags-input.tags-field-focus .tags .input');
	    element.focus();
    }

    // isLoggedInWithGoogle() {
	//     return this.scope.loggedInWithGoogle == true;
    // }

    addedTag (tag)  {
        var unique = true;
        var url = tag.text;
        var regex = new RegExp(
            "^" +
            // protocol identifier
            "(?:(?:https?|ftp)://)" +
            // user:pass authentication
            "(?:\\S+(?::\\S*)?@)?" +
            "(?:" +
            // IP address exclusion
            // private & local networks
            "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
            "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
            "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
            // IP address dotted notation octets
            // excludes loopback network 0.0.0.0
            // excludes reserved space >= 224.0.0.0
            // excludes network & broacast addresses
            // (first & last IP address of each class)
            "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
            "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
            "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
            "|" +
            // host name
            "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
            // domain name
            "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
            // TLD identifier
            "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
            // TLD may end with dot
            "\\.?" +
            ")" +
            // port number
            "(?::\\d{2,5})?" +
            // resource path
            "(?:[/?#]\\S*)?" +
            "$", "i"
        );

        url = url.replace(/^((:)?\/{0,2})/,"");

        if(!url.match(/^[a-zA-Z]+:\/\//)) {
            url = 'http://' + url;
        }
        if(this.scope.user.website) {
            for (var i = 0, len = this.scope.user.website.length; i < len; i++) {
                if(this.scope.user.website[i].text == url) {
                    unique = false;
                    break;
                }
            }
        }

        if(unique && regex.test(url) === true) {
            tag.text = url;
            return true;
        } else {
            return false;
        }
    }

}

export class AffiliateRegistrationController {

    /* @ngInject */
    constructor($scope, config, AffiliateRegistrar, AdperioAffiliateRegistrar, $modal) {
        let ctrl_this = this;
        this.scope = $scope;
        this.modal = $modal;
        $scope.first_recaptcha_validation = true;
        // $scope.loggedInWithGoogle = false;
        $scope.recaptcha_checked = null;
        $scope.user = {
            referee_code: config.referee_code,
            registration_info: {
                email: {
                    list: 'Owned',
                    size: '0 - 49,999'
                }
            }
        };

        $scope.tmpUser = {
            type: 'affiliate'
        };
        $scope.errors = {
            messages: null
        };

        $scope.uploadedFiles = {
            files: []
        };

        $scope.countries = config.countries;
        $scope.states = config.states;

        $scope.step = 1;
        $scope.submited_step1 = false;

        $scope.application_id = null;
        $scope.account_request = null;
        $scope.login_forget = 1;

        $scope.lock_submit_till_response = false;

        $scope.hasError = function (ngModel) {
            if (typeof ngModel == 'undefined' || ngModel.$touched === false) {
                return false;
            }

            return ngModel.$invalid;
        };

        $scope.im_types = [
            {name: 'Google'},
            {name: 'Skype'},
            {name: 'Telegram'},
            {name: 'WhatsApp'}
        ];

        $scope.heard_from_sources = [
            {name: 'Search Engine'},
            {name: 'Social Media'},
            {name: 'Referral'},
            {name: 'Trade Show'},
            {name: 'Email'},
            {name: 'Trade Publication'},
            {name: 'Other'},
        ];

        // $scope.affiliate_subtypes = [
        //     {name: "App Developer", value: "app_developer"},
        //     {name: "Mailer", value: "mailer"},
        //     {name: "Media Buyer", value: "media_buyer"},
        //     {name: "Website Owner", value: "website_owner"},
        // ];

        $scope.earnings = [
            {name: "$0 - $4,999"},
            {name: "$5,000 - $9,999"},
            {name: "$10,000 - $24,999"},
            {name: "$25,000 - $49,999"},
            {name: "$50,000 - $99,999"},
            {name: "$100,000+"}
        ];

        $scope.adperio_traffic_type = [
            {name: 'Mobile'},
            {name: 'Web'},
            {name: 'Both'}
        ];

        $scope.affiliate_traffic_type = [
            {name: 'Mobile'},
            {name: 'Web'},
            {name: 'Both'}
        ];

        $scope.traffic_distribution = [
            {name: 'Incent'},
            {name: 'Non-Incent'},
            {name: 'Both'}
        ];

        $scope.acc_executive_check = {
            checked: false
        };

        $scope.yes_no = [
        {name: 'Yes'},
        {name: 'No'}
        ];

        $scope.prevent_multiple_submits = function (lock){
                $scope.lock_submit_till_response = lock;
        };

        $scope.completedStep1 = function() {
            $scope.step = 2;
            $scope.submited_step1 = true;

            $scope.clearErrors();

            if($scope.application_id) {
                $scope.showAccountRequest = false;
            }

        };

        $scope.completedStep2 = function() {
            $scope.step = 3;
            $scope.submited_step1 = true;
            
            $scope.clearErrors();

            $scope.showAccountRequest = false;
        };

        $scope.submitStep1 = function () {
            $scope.clearErrors();
            $scope.prevent_multiple_submits(true);
            if($scope.user.payment_info) {
                $scope.user.company = $scope.user.payment_info.company_name;
            }
            $scope.user.step1_completed = true;

            let data = angular.copy($scope.user);

            grecaptcha.ready(function() {
                grecaptcha.execute($scope.get_recaptcha_site_key(), {action: 'registerForm'}).then(function(token) {
                    if(token) {
                        data.recaptcha_v3 = token;
                        AffiliateRegistrar.save(data).then((response) => {
                            $scope.application_id = response.id;
                            $scope.prevent_multiple_submits(false);
                            $scope.completedStep1();
                        }, (response) => {
                            $scope.prevent_multiple_submits(false);
                            $scope.showErrors(response);
                        });
                    }
                });
            });
        };

        $scope.get_recaptcha_token = function () {
            return $( "input[name='recaptcha_v3']" ).val();
        };

        $scope.submitChangedData = function(){
            if($scope.clickbooth != true) return;

            let data = angular.copy($scope.user);
            data.recaptcha_checked = $scope.recaptcha_checked;
            data.first_recaptcha_validation = $scope.first_recaptcha_validation;

            if(!data_get(data, 'email') || (data_get(data, 'password') && data_get(data, 'password') !== data_get(data, 'password_confirmation'))){
                return;
            }

            if(data.payment_info) {
                data.company = data.payment_info.company_name;
            }

            if ($scope.first_recaptcha_validation == true) {
                grecaptcha.ready(function() {
                    grecaptcha.execute($scope.get_recaptcha_site_key(), {action: 'registerForm'}).then(function(token) {
                        if(token) {
                            data.recaptcha_v3 = token;

                            AffiliateRegistrar.save(data).then((response) => {
                                $scope.recaptcha_checked = response.data.recaptcha_checked;
                                $scope.application_id = response.id;
                                $scope.prevent_multiple_submits(false);
                            }, (response) => {
                                $scope.recaptcha_checked = response.recaptcha_checked;
                                $scope.prevent_multiple_submits(false);
                            });
                        }
                    });
                });

            } else {
                AffiliateRegistrar.save(data).then((response) => {
                    $scope.recaptcha_checked = response.data.recaptcha_checked;
                    $scope.application_id = response.id;
                    $scope.prevent_multiple_submits(false);
                }, (response) => {
                    $scope.recaptcha_checked = response.recaptcha_checked;
                    $scope.prevent_multiple_submits(false);
                });
            }
        };

        $scope.get_recaptcha_site_key = function () {
            return $( "input[name='site_key']" ).val();
        };

        $scope.submitStep2 = function (files) {
            $scope.clearErrors();
            $scope.prevent_multiple_submits(true);
            $scope.user.affiliate_subtype = 'media_buyer';

            if($scope.user.affiliate_subtype != 'mailer') {
                delete $scope.user.registration_info.list;
                delete $scope.user.registration_info.size;
            }

            let pardot_data = $scope.getPardotFields();

            AffiliateRegistrar.register($scope.user, files, $scope.application_id, pardot_data)
                .success(function (response, status, headers, config) {
                    $scope.prevent_multiple_submits(false);
                    window.location.href = response.data.redirect_to;
                }).error(function (response) {
                $scope.prevent_multiple_submits(false);
                $scope.showErrors(response);
                });
        };

        $scope.submitStep3 = function (files) {
            $scope.clearErrors();
            $scope.prevent_multiple_submits(true);
            $scope.user.affiliate_subtype = 'media_buyer';
            
            delete $scope.user.registration_info.list;
            delete $scope.user.registration_info.size;
            
            let pardot_data = $scope.getPardotFields();
            
            AffiliateRegistrar.register($scope.user, files, $scope.application_id, pardot_data)
                .success(function (response) {
                    $scope.prevent_multiple_submits(false);
                    window.location.href = response.data.redirect_to;
                }).error(function (response) {
                $scope.prevent_multiple_submits(false);
                $scope.showErrors(response);
            });
        };
        
        $scope.registerAdperio = function () {

            if($scope.user.payment_info) {
                $scope.user.company = $scope.user.payment_info.company_name;
            }

            $scope.clearErrors();
            $scope.prevent_multiple_submits(true);

            AdperioAffiliateRegistrar.register($scope.user)
                .success(function (response, status, headers, config) {
                    $scope.prevent_multiple_submits(false);
                    window.location.href = response.data.redirect_to;
                }).error(function (response) {
                $scope.prevent_multiple_submits(false);
                $scope.showErrors(response);
            });
        };

        $scope.remove = function (index, files) {
            files.splice(index, 1);
        };

        $scope.countryChanged = function() {
            $scope.user.payment_info.state = null;
            $scope.user.payment_info.city = null;
            $scope.user.payment_info.zip_code = null;
        };

        $scope.clearErrors = function() {
            $scope.errors.messages = null
        };

        $scope.showErrors = function(response) {
            var errors = angular.copy(response);
            if(!isUndefinedOrNull(errors.error) && !isUndefinedOrNull(errors.error.message)) {
                errors = [[errors.error.message]];
            } else if(!isUndefinedOrNull(errors.message)) {
                errors = [[errors.message]];
            }

            $scope.errors.messages = errors;
        };

        $scope.continueApplication = function() {
            $scope.clearErrors();

            AffiliateRegistrar.continue($scope.tmpUser).then((response) => {
                if (response.data && response.data.redirect_to) {
                    window.location.href = response.data.redirect_to;
                }
            }, (response) => {
                $scope.showErrors(response);
            });
        };

        $scope.checkExecutive = function () {
            return typeof $scope.user.account_executive !== 'undefined'
                && angular.isString($scope.user.account_executive)
                && $scope.user.account_executive != '';
        };

        $scope.setExecutive = function () {
            $scope.user.account_executive_checked = acc_executive_check.checked;
            if (!acc_executive_check.checked) {
                $scope.user.account_executive = '';
            }
        };

        $scope.$watch('user.payment_info.zip_code', function (zip_code) {
            if(config.geolocationUs && zip_code && ($scope.user.payment_info.country == 'US' || $scope.user.payment_info.country == null)){
                let geocoder = new google.maps.Geocoder();
                geocoder.geocode({componentRestrictions: {country: 'US', postalCode: zip_code}}, function(results, status) {

                    if(status === "OK"){
                        let country, state, city;

                        for (let i = 0; i < results[0].address_components.length; i++){
                            if(results[0].address_components[i].types.includes("country")){
                                country = results[0].address_components[i].short_name;
                            }
                            if(results[0].address_components[i].types.includes("administrative_area_level_1")){
                                state = results[0].address_components[i].short_name;
                            }
                            if (results[0].address_components[i].types.includes("locality")){
                                city = results[0].address_components[i].long_name;
                            }else if (!results[0].address_components[i].types.includes("locality") && results[0].address_components[i].types.includes("neighborhood") ){
                                city = results[0].address_components[i].long_name;
                            }
                        }
                        $scope.$applyAsync(() => {
                            $scope.user.payment_info.country = country;
                            $scope.user.payment_info.state = state;
                            $scope.user.payment_info.city = city;
                        });
                    }
                });
            }
        });

        /*$scope.$watch(function () {
            return JSON.stringify($scope.user);
        }, function(n,o){

            if( n != o){

                if(data_get($scope.user, 'affiliate_subtype') !== 'mailer'){
                    delete $scope.user.registration_info.email;
                }

                if(data_get($scope.user, 'affiliate_subtype') !== 'media_buyer' && $scope.user.registration_info.traffic_type && $scope.user.registration_info.traffic_type.media_buyer){
                    delete $scope.user.registration_info.traffic_type.media_buyer;
                }

                if(data_get($scope.user, 'affiliate_subtype') === 'media_buyer'){
                    if(!$scope.user.registration_info.traffic_type){
                        $scope.user.registration_info.traffic_type = {media_buyer:{}};
                    }
                }

                if(!(registerForm.$invalid || $scope.lock_submit_till_response)){
                    if(ctrl_this.submitChangedDataTimeout){
                        clearTimeout(ctrl_this.submitChangedDataTimeout);
                    }
                    ctrl_this.submitChangedDataTimeout = setTimeout(function() {
                        if (!isUndefinedOrNull($scope.user.email) && $scope.first_recaptcha_validation == true) {
                            $scope.submitChangedData();
                            $scope.first_recaptcha_validation = false;
                        } else {
                            $scope.submitChangedData();
                        }
                    }, 500);
                }
            }
        });*/

        $scope.getPardotFields = function () {
            let pardot = [];
            pardot ['First_Source'] = $( "input[name='First_Source']" ).val();
            pardot ['First_Medium'] = $( "input[name='First_Medium']" ).val();
            pardot ['First_Term'] = $( "input[name='First_Term']" ).val();
            pardot ['First_Content'] = $( "input[name='First_Content']" ).val();
            pardot ['First_Campaign'] = $( "input[name='First_Campaign']" ).val();
            pardot ['Last_Source'] = $( "input[name='Last_Source']" ).val();
            pardot ['Last_Medium'] = $( "input[name='Last_Medium']" ).val();
            pardot ['Last_Term'] = $( "input[name='Last_Term']" ).val();
            pardot ['Last_Content'] = $( "input[name='Last_Content']" ).val();
            pardot ['Last_Campaign'] = $( "input[name='Last_Campaign']" ).val();

            return pardot;
        };
    }

    fill (data, id) {
        this.scope.$applyAsync(() => {
            if (data.toa == 'on') {
                data.toa = true;
            }

            angular.extend(this.scope.user, data);
            this.scope.application_id = id;
            
            if(!isUndefinedOrNull(id) && data.step1_completed === true) {
                this.scope.completedStep1();
            } else {
                this.scope.showAccountRequest = !(!isUndefinedOrNull(data) && data.is_re_apply);
                if (!isUndefinedOrNull(this.scope.application_id)) {
                    this.scope.recaptcha_checked = true;
                    this.scope.first_recaptcha_validation = false;
                }
            }
        });
    }

    addFocus() {
        var element = $('tags-input.tags-field-focus .tags .input');
        element.focus();
    }

    // isLoggedInWithGoogle() {
    //     return this.scope.loggedInWithGoogle == true;
    // }

    addedTag (tag)  {
        var unique = true;
        var url = tag.text;

        var regex = new RegExp(
            "^" +
                // protocol identifier
            "(?:(?:https?|ftp)://)" +
                // user:pass authentication
            "(?:\\S+(?::\\S*)?@)?" +
            "(?:" +
                // IP address exclusion
                // private & local networks
            "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
            "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
            "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
                // IP address dotted notation octets
                // excludes loopback network 0.0.0.0
                // excludes reserved space >= 224.0.0.0
                // excludes network & broacast addresses
                // (first & last IP address of each class)
            "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
            "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
            "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
            "|" +
                // host name
            "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
                // domain name
            "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
                // TLD identifier
            "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
                // TLD may end with dot
            "\\.?" +
            ")" +
                // port number
            "(?::\\d{2,5})?" +
                // resource path
            "(?:[/?#]\\S*)?" +
            "$", "i"
        );

        url = url.replace(/^((:)?\/{0,2})/,"");

        if(!url.match(/^[a-zA-Z]+:\/\//)) {
            url = 'http://' + url;
        }

        if(this.scope.user.website) {
            for (var i = 0, len = this.scope.user.website.length; i < len; i++) {
                if(this.scope.user.website[i].text == url) {
                    unique = false;
                    break;
                }
            }
        }

        if(unique && regex.test(url) === true) {
            tag.text = url;
            return true;
        } else {
            return false;
        }
    }

}
